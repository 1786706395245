import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';

import { store } from '../context/store';
import actions from '../context/actions';

const Login = () => {
  const [password, setPassword] = useState('');
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch({ type: actions.SET_PASSWORD, payload: password });
    navigate('/');
  }

  return (
    <div className="economist__password-wrapper">
      <h1>The Economist</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={e => setPassword(e.target.value)}
        />
      </form>
    </div>
  );
}

export default Login;
